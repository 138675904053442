import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AdventHealthNorthLandingPage = () => (
  <PhysicianLandingPage
    physician="AdventHealth Medical Group Urology at Altamonte Springs"
    institution="AdventHealth"
    referralCode="ADVENTNORTH"
  />
)

export default AdventHealthNorthLandingPage
